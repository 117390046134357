import React from "react"
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Animate from "./animate"
import * as styles from "../styles/_indexTeam.module.scss"
import { linkButton, nega, iconInternal } from '../styles/_btn.module.scss'

const Team = () => {
  const { allImageSharp } = useStaticQuery(
    graphql`
      query {
        allImageSharp(
          filter: {original: {src: {regex: "/member/"}}}
          sort: {fields: original___src, order: ASC}
        ) {
          nodes {
            gatsbyImageData
            id
          }
        }
      }
    `
  )
  return (
    <section className={styles.team}>
      <Animate className="fadeIn" triggerOnce={true}>

        <figure className={styles.images}>
          {
            allImageSharp.nodes.map(node => (
              <Animate key={node.id} className="clipL" triggerOnce={true}>
                <div className={styles.block}>
                  <GatsbyImage
                    image={getImage(node)}
                    alt="社員"
                    placeholder="transparent"
                    loading="lazy"
                  />
                </div>
              </Animate>
            ))
          }
        </figure>

        <div className={styles.contents}>
          <Animate className="fadeIn" triggerOnce={true}>
            <h2 className="title2 nega">Team</h2>
            <p>グループIT推進本部で働くチームの役割について紹介します。</p>
            <p className={styles.button}>
              <Link to="/team/" className={`${linkButton} ${nega}`}>
                チームについて
                <span className={iconInternal}></span>
              </Link>
            </p>
          </Animate>
        </div>
      </Animate>
    </section >
  )
}

export default Team
