import React, { useEffect } from "react"
import Animate from "./animate"
import { useInView } from "react-intersection-observer"

import AboutVideo from "../images/about.mp4"
import * as styles from "../styles/_indexAbout.module.scss"

const AutoPlayingVideo = ({ children, threshold = 0.15, ...playerProps }) => {
  const [ref, inView] = useInView({ threshold })

  useEffect(() => {
    if (inView) {
      ref.current?.play()
    } else {
      ref.current?.pause()
    }
  }, [ref, inView])

  return (
    <video
      ref={ref}
      autoPlay
      playsInline
      muted
      loop
      controls
      {...playerProps}
    >
      {children}
    </video>
  )
}

const About = () => {

  return (
    <section className={styles.about}>

      <Animate className="clipL" triggerOnce={true}>
        <p className={styles.scroll} style={{ transitionDelay: `3s` }}>Scroll</p>
      </Animate>

      <figure className={styles.movie}>
        <AutoPlayingVideo poster="/about.png">
          <source src={AboutVideo} type="video/mp4" />
        </AutoPlayingVideo>
        <Animate className="clipL" triggerOnce={true}>
          <div className={styles.message} style={{ transitionDelay: `1.5s` }}>
            <p className={styles.message_en}>Support the Future</p>
            <p className={styles.message_jp}>サイバーエージェントグループの未来を<br />ITで支える</p>
          </div>
        </Animate>
      </figure>

      <div className={styles.contents}>
        <Animate className="fadeIn" triggerOnce={true}>
          <div className={styles.title}>
            <h2 className="title2 posi">About</h2>
          </div>
        </Animate>
        <Animate className="fadeIn" triggerOnce={true}>
          <div className={styles.text}>
            <p>グループIT推進本部は、各種専門組織（データ、インフラ、セキュリティ、コーポレートIT等）とコンサルティング部隊から構成され、最先端のIT技術を用いてサイバーエージェントグループの課題解決や事業サポートを行う組織です。</p>
            <p>各種パブリッククラウドやCDN業者との大規模契約をとりまとめてITコストを削減したり、国内および海外の各種拠点の開発組織と直接連携し、開発・運用の効率化も行っています。</p>
          </div>
        </Animate>
      </div>

    </section>
  )
}

export default About
