import React from "react"

import Layout from "../components/layout"
import About from "../components/indexAbout"
import Team from "../components/indexTeam"
import Careers from "../components/indexCareers"
import Bg1 from "../components/bg1"
import Bg2 from "../components/bg2"
import Seo from "../components/seo"

const IndexPage = () => {

  return (
    <Layout home={true}>
      <About />
      <Bg1 />
      <Team />
      <Bg2 />
      <Careers />
    </Layout>
  )
}

export const Head = () => <Seo />

export default IndexPage
