import React from "react"
import Animate from "./animate"
import { Link } from "gatsby"

import * as styles from "../styles/_indexCareers.module.scss"
import { linkButton, nega, iconInternal } from '../styles/_btn.module.scss'

const Careers = () => {

  return (
    <Animate className="fadeIn" triggerOnce={true}>
      <section className={styles.careers}>

        <div className={styles.title}>
          <h2 className="title2 posi">Careers</h2>
        </div>

        <div className={styles.text} style={{ transitionDelay: `0.25s` }}>
          <p>グループIT推進本部では一緒に働く仲間を募集しています。</p>
          <p className={styles.button}>
            <Link to="/careers/" className={`${linkButton} ${nega}`}>
              採用情報
              <span className={iconInternal}></span>
            </Link>
          </p>
        </div>

      </section>
    </Animate>
  )
}

export default Careers
