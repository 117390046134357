// extracted by mini-css-extract-plugin
export var about = "_indexAbout-module--about--EA11m";
export var contents = "_indexAbout-module--contents--RAHIQ";
export var message = "_indexAbout-module--message--RnyAT";
export var message_en = "_indexAbout-module--message_en--6wMOx";
export var message_jp = "_indexAbout-module--message_jp--vMa6h";
export var movie = "_indexAbout-module--movie--ApJvi";
export var navScroll = "_indexAbout-module--nav-scroll--7mRCE";
export var scroll = "_indexAbout-module--scroll--uK+2u";
export var text = "_indexAbout-module--text--jlXa7";
export var title = "_indexAbout-module--title--T-Ds6";