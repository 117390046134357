import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import * as styles from "../styles/_bg.module.scss"

const Bg = () => {

  return (
    <div className={styles.bg}>
      <StaticImage
        src="../images/bg2.jpg"
        loading="eager"
        alt="社内イメージ"
      />
    </div>
  )
}

export default Bg
